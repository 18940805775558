.App {
  display: flex;
  flex-direction: column;
  align-items: center;


  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: white;
    margin: 22px 2em .6em 2em;
    text-overflow: ellipsis;
  }

  #top-bar {
    * {
      transition: all 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    font-size: medium;
    margin: 0 2em 1em 2em;

    .left {
      display: flex;
      flex-wrap: wrap;

      .choose-round {
        display: flex;

        .round {
          margin: .2em;
          padding: .6em;
          border-radius: 50%;
          width: 58px;
          height: 58px;
          background-image: url('./data/golf-ball3.png');
          background-repeat: no-repeat;
          background-position: center center;
          border: 1px solid green;
          background-size: cover;
          font-weight: 900;
          color: rgba(0, 128, 0, 0.75);
          filter: blur(5);
          box-shadow: 0 0 0 black, 0 0 8px green;
          font-size: x-large;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .round.active {
          cursor: pointer;
          background-color: green;
          box-shadow: 0 0 0 black, 0 0 20px green;
          transform: scale(1.15);
          z-index: 100;
        }

        .round:hover {
          cursor: pointer;
          box-shadow: 0 0 0 black, 0 0 20px green;
          transform: scale(1.1);
          z-index: 100;
        }
      }


      .dropdown {
        margin: auto;
        margin-right: 20px;
      }

      #select-player {
        font-size: 18px;
        font-weight: 600;
        min-width: 9em;
        max-width: 9em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        outline: none;
        border: none;
        padding: .6em;
        border-radius: 8px;
        background: #16AF78;
        color: white;
        filter: blur(5);
        box-shadow: 0 0 0 black, 0 0 14px green;
      }

      #select-player:hover {
        cursor: pointer;
        box-shadow: 0 0 0 black, 0 0 25px green;
        z-index: 100;
      }

      #dropdown-menu {
        font-size: 1.1rem;
        max-height: 18rem;
        overflow-y: scroll;
        background: rgba(0, 0, 0, 0.7);
        z-index: 5000;

        scrollbar-width: thin;

        &::-webkit-scrollbar {
          width: 2px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #888;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .player-op {
          color: white;
          word-wrap: none;
          white-space: pre-wrap;
        }

        .player-op:hover {
          background: black;
          font-size: 22px;
        }
      }
    }

    .right {

      button {
        font-size: 18px;
        font-weight: 600;
        margin: .5em;
        text-transform: capitalize;
        width: 8em;
        color: white;
        padding: .6em;
        border-radius: 8px;
        outline: none;
        border: none;
        background: #16AF78;
        filter: blur(5);
        box-shadow: 0 0 0 black, 0 0 14px green;
      }

      button:hover {
        cursor: pointer;
        box-shadow: 0 0 0 black, 0 0 25px green;
        z-index: 100;
      }
    }

    @media (max-width: 610px) {
      .left {
        display: grid;

        .choose-round {
          margin: .6em auto;
        }

        .dropdown {
          margin: auto;

          #select-player {
            width: fit-content;
          }
        }
      }

      .right {
        display: grid;
      }
    }
  }

  #map {
    transition: none;
    width: 100%;
    height: calc(100vh - 180px);
    max-height: calc(100vh - 180px);
    background: black;

    .marker-popup {
      div {
        font-weight: bold;
        text-align: center;
      }
    }
  }
}

.add-player-modal {
  * {
    transition: all .1s ease-in-out;
    background: rgba(0, 0, 0, 0);
    color: white;
  }

  #add-player-body {
    box-shadow: rgb(26, 26, 26) 2px 10px 25px 2px;

    display: flex;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;


    form {
      margin: 2em;

      input {
        outline: none;
        font-size: large;
        border: 1px solid #16AF78;
        border-radius: 4px;
        border-color: #16AF78;
        padding: 6px;
        margin: .6em;
        box-shadow: 0 0 0 black, 0 0 2px green;
      }

      input:hover,
      input:focus {
        box-shadow: 0 0 0 black, 0 0 15px green;
      }

      button {
        font-weight: bold;
        margin: .5em;
        text-transform: capitalize;
        width: 8em;
        color: white;
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: none;
        background: #16AF78;
        filter: blur(5);
        box-shadow: 0 0 0 black, 0 0 8px green;
      }

      button:hover {
        cursor: pointer;
        box-shadow: 0 0 0 black, 0 0 18px green;
      }
    }

    span {
      font-style: italic;
      font-size: 16px;
      width: 100%;
      justify-self: start;
      font-weight: bold;
      color: #16AF78;
      margin-left: 1em;
    }

    th,
    td {
      border: 1px solid white;
    }

    table {
      margin-bottom: 1em;
    }

    table,
    tr td {
      border-collapse: separate;
      font-size: large;
      width: 100%;
      padding: 5px;
    }

    tbody {
      display: block;
      height: 40vh;
      overflow-y: scroll;

      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0px;
      }

    }

    th {
      padding: 5px;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
}

.add-shot-modal {
  * {
    transition: all .1s ease-in-out;
    background: rgba(0, 0, 0, 0);
    color: white;
  }

  #add-shot-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    box-shadow: rgb(26, 26, 26) 2px 10px 25px 2px;

    .dropdown {
      margin-top: .4em;
    }

    .dropdown-toggle {
      font-size: 20px;
      font-weight: bold;
      min-width: 9em;
      max-width: 9em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: capitalize;
      outline: none;
      border: none;
      padding: 10px;
      border-radius: 8px;
      background: #16AF78;
      color: white;
      filter: blur(5);
      box-shadow: 0 0 0 black, 0 0 14px green;
    }

    .dropdown-toggle:hover {
      cursor: pointer;
      box-shadow: 0 0 0 black, 0 0 25px green;
      z-index: 100;
    }

    .dropdown-menu {
      font-size: 18px;
      max-height: 300px;
      overflow-y: scroll;
      background: rgba(0, 0, 0, 0.7);

      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 2px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .dropdown-item {
        color: white;
        word-wrap: none;
        white-space: pre-wrap;
      }

      .dropdown-item:hover {
        background: black;
        font-size: 22px;
      }
    }

    .choose-round {
      display: flex;
      margin-bottom: 2em;

      .round {
        margin: .2em;
        padding: .6em;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        background-image: url('./data/golf-ball3.png');
        background-repeat: no-repeat;
        background-position: center center;
        border: 1px solid green;
        background-size: cover;
        font-weight: 900;
        color: rgba(0, 128, 0, 0.75);
        filter: blur(5);
        box-shadow: 0 0 0 black, 0 0 8px green;
        font-size: x-large;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .round.active {
        cursor: pointer;
        background-color: green;
        box-shadow: 0 0 0 black, 0 0 20px green;
        transform: scale(1.15);
        z-index: 100;
      }

      .round:hover {
        cursor: pointer;
        box-shadow: 0 0 0 black, 0 0 20px green;
        transform: scale(1.1);
        z-index: 100;
      }
    }

    input {
      outline: none;
      font-size: large;
      border: 1px solid #16AF78;
      border-radius: 4px;
      border-color: #16AF78;
      padding: 6px;
      margin-bottom: 0;
      box-shadow: 0 0 0 black, 0 0 2px green;

      &::placeholder {
        text-transform: capitalize;
      }
    }

    input:hover,
    input:focus {
      box-shadow: 0 0 0 black, 0 0 15px green;
    }

    span {
      color: #16AF78;
      margin-top: .2em;
    }

    button {
      font-size: 20px;
      font-weight: bold;
      margin-top: 2em;
      margin-bottom: 2em;
      text-transform: capitalize;
      width: 8em;
      color: white;
      padding: 10px;
      border-radius: 8px;
      outline: none;
      border: none;
      background: #16AF78;
      filter: blur(5);
      box-shadow: 0 0 0 black, 0 0 8px green;

    }

    button:hover {
      cursor: pointer;
      box-shadow: 0 0 0 black, 0 0 18px green;
    }
  }
}



.instruction-modal {
  * {
    transition: all .1s ease-in-out;
    background: rgba(0, 0, 0, 0);
    color: white;
  }


  #instruction-body {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    gap: 2em;
    box-shadow: rgb(26, 26, 26) 2px 10px 25px 2px;

    h4 {
      margin-top: 2em;
    }

    .buttons {
      display: flex;
      gap: 2em;
      margin-bottom: 2em;

      button {
        font-size: 20px;
        font-weight: bold;
        text-transform: capitalize;
        width: 8em;
        color: white;
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: none;
        background: #16AF78;
        filter: blur(5);
        box-shadow: 0 0 0 black, 0 0 8px green;
      }

      button:hover {
        cursor: pointer;
        box-shadow: 0 0 0 black, 0 0 18px green;
      }
    }

  }
}

.submit-shot-point-modal {
  * {
    transition: all .1s ease-in-out;
    background: rgba(0, 0, 0, 0);
    color: white;
  }


  #submit-shot-point-body {

    display: flex;
    gap: 1em;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    box-shadow: rgb(26, 26, 26) 2px 10px 25px 2px;
    font-size: 1.4em;

    .box {
      display: grid;
      grid-template-columns: 5em 1fr;
      gap: 4em;
      align-items: center;
      margin-top: 1em;

      img {
        height: 10em;
        opacity: .9;
      }

      .right {
        display: flex;
        flex-direction: column;
        // align-items:stretch;
      }
    }




    .buttons {
      display: flex;
      gap: 2.5em;
      margin-bottom: 1em;
      margin-top: 1em;

      button {
        font-size: 20px;
        font-weight: bold;
        text-transform: capitalize;
        width: 8em;
        color: white;
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: none;
        background: #16AF78;
        filter: blur(5);
        box-shadow: 0 0 0 black, 0 0 8px green;
      }

      button:hover {
        cursor: pointer;
        box-shadow: 0 0 0 black, 0 0 18px green;
      }
    }


  }
}