@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
*,
*:after,
*:before {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black; }

::-webkit-scrollbar {
  width: 0px; }

.App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .App h1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 8px;
    gap: 8px;
    color: white;
    margin: 22px 2em .6em 2em;
    text-overflow: ellipsis; }
  .App #top-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: medium;
    margin: 0 2em 1em 2em; }
    .App #top-bar * {
      transition: all 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
    .App #top-bar .left {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .App #top-bar .left .choose-round {
        display: -webkit-flex;
        display: flex; }
        .App #top-bar .left .choose-round .round {
          margin: .2em;
          padding: .6em;
          border-radius: 50%;
          width: 58px;
          height: 58px;
          background-image: url(/static/media/golf-ball3.215b0dd1.png);
          background-repeat: no-repeat;
          background-position: center center;
          border: 1px solid green;
          background-size: cover;
          font-weight: 900;
          color: rgba(0, 128, 0, 0.75);
          -webkit-filter: blur(5);
                  filter: blur(5);
          box-shadow: 0 0 0 black, 0 0 8px green;
          font-size: x-large;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center;
          -webkit-justify-content: center;
                  justify-content: center; }
        .App #top-bar .left .choose-round .round.active {
          cursor: pointer;
          background-color: green;
          box-shadow: 0 0 0 black, 0 0 20px green;
          -webkit-transform: scale(1.15);
                  transform: scale(1.15);
          z-index: 100; }
        .App #top-bar .left .choose-round .round:hover {
          cursor: pointer;
          box-shadow: 0 0 0 black, 0 0 20px green;
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
          z-index: 100; }
      .App #top-bar .left .dropdown {
        margin: auto;
        margin-right: 20px; }
      .App #top-bar .left #select-player {
        font-size: 18px;
        font-weight: 600;
        min-width: 9em;
        max-width: 9em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        outline: none;
        border: none;
        padding: .6em;
        border-radius: 8px;
        background: #16AF78;
        color: white;
        -webkit-filter: blur(5);
                filter: blur(5);
        box-shadow: 0 0 0 black, 0 0 14px green; }
      .App #top-bar .left #select-player:hover {
        cursor: pointer;
        box-shadow: 0 0 0 black, 0 0 25px green;
        z-index: 100; }
      .App #top-bar .left #dropdown-menu {
        font-size: 1.1rem;
        max-height: 18rem;
        overflow-y: scroll;
        background: rgba(0, 0, 0, 0.7);
        z-index: 5000;
        scrollbar-width: thin;
        /* Track */
        /* Handle */
        /* Handle on hover */ }
        .App #top-bar .left #dropdown-menu::-webkit-scrollbar {
          width: 2px; }
        .App #top-bar .left #dropdown-menu::-webkit-scrollbar-track {
          background: #f1f1f1; }
        .App #top-bar .left #dropdown-menu::-webkit-scrollbar-thumb {
          background: #888; }
        .App #top-bar .left #dropdown-menu::-webkit-scrollbar-thumb:hover {
          background: #555; }
        .App #top-bar .left #dropdown-menu .player-op {
          color: white;
          word-wrap: none;
          white-space: pre-wrap; }
        .App #top-bar .left #dropdown-menu .player-op:hover {
          background: black;
          font-size: 22px; }
    .App #top-bar .right button {
      font-size: 18px;
      font-weight: 600;
      margin: .5em;
      text-transform: capitalize;
      width: 8em;
      color: white;
      padding: .6em;
      border-radius: 8px;
      outline: none;
      border: none;
      background: #16AF78;
      -webkit-filter: blur(5);
              filter: blur(5);
      box-shadow: 0 0 0 black, 0 0 14px green; }
    .App #top-bar .right button:hover {
      cursor: pointer;
      box-shadow: 0 0 0 black, 0 0 25px green;
      z-index: 100; }
    @media (max-width: 610px) {
      .App #top-bar .left {
        display: grid; }
        .App #top-bar .left .choose-round {
          margin: .6em auto; }
        .App #top-bar .left .dropdown {
          margin: auto; }
          .App #top-bar .left .dropdown #select-player {
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content; }
      .App #top-bar .right {
        display: grid; } }
  .App #map {
    transition: none;
    width: 100%;
    height: calc(100vh - 180px);
    max-height: calc(100vh - 180px);
    background: black; }
    .App #map .marker-popup div {
      font-weight: bold;
      text-align: center; }

.add-player-modal * {
  transition: all .1s ease-in-out;
  background: rgba(0, 0, 0, 0);
  color: white; }

.add-player-modal #add-player-body {
  box-shadow: #1a1a1a 2px 10px 25px 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: rgba(0, 0, 0, 0.7);
  height: 100%; }
  .add-player-modal #add-player-body form {
    margin: 2em; }
    .add-player-modal #add-player-body form input {
      outline: none;
      font-size: large;
      border: 1px solid #16AF78;
      border-radius: 4px;
      border-color: #16AF78;
      padding: 6px;
      margin: .6em;
      box-shadow: 0 0 0 black, 0 0 2px green; }
    .add-player-modal #add-player-body form input:hover,
    .add-player-modal #add-player-body form input:focus {
      box-shadow: 0 0 0 black, 0 0 15px green; }
    .add-player-modal #add-player-body form button {
      font-weight: bold;
      margin: .5em;
      text-transform: capitalize;
      width: 8em;
      color: white;
      padding: 10px;
      border-radius: 8px;
      outline: none;
      border: none;
      background: #16AF78;
      -webkit-filter: blur(5);
              filter: blur(5);
      box-shadow: 0 0 0 black, 0 0 8px green; }
    .add-player-modal #add-player-body form button:hover {
      cursor: pointer;
      box-shadow: 0 0 0 black, 0 0 18px green; }
  .add-player-modal #add-player-body span {
    font-style: italic;
    font-size: 16px;
    width: 100%;
    justify-self: start;
    font-weight: bold;
    color: #16AF78;
    margin-left: 1em; }
  .add-player-modal #add-player-body th,
  .add-player-modal #add-player-body td {
    border: 1px solid white; }
  .add-player-modal #add-player-body table {
    margin-bottom: 1em; }
  .add-player-modal #add-player-body table,
  .add-player-modal #add-player-body tr td {
    border-collapse: separate;
    font-size: large;
    width: 100%;
    padding: 5px; }
  .add-player-modal #add-player-body tbody {
    display: block;
    height: 40vh;
    overflow-y: scroll;
    scrollbar-width: none; }
    .add-player-modal #add-player-body tbody::-webkit-scrollbar {
      width: 0px; }
  .add-player-modal #add-player-body th {
    padding: 5px; }
  .add-player-modal #add-player-body thead,
  .add-player-modal #add-player-body tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; }

.add-shot-modal * {
  transition: all .1s ease-in-out;
  background: rgba(0, 0, 0, 0);
  color: white; }

.add-shot-modal #add-shot-body {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  box-shadow: #1a1a1a 2px 10px 25px 2px; }
  .add-shot-modal #add-shot-body .dropdown {
    margin-top: .4em; }
  .add-shot-modal #add-shot-body .dropdown-toggle {
    font-size: 20px;
    font-weight: bold;
    min-width: 9em;
    max-width: 9em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 8px;
    background: #16AF78;
    color: white;
    -webkit-filter: blur(5);
            filter: blur(5);
    box-shadow: 0 0 0 black, 0 0 14px green; }
  .add-shot-modal #add-shot-body .dropdown-toggle:hover {
    cursor: pointer;
    box-shadow: 0 0 0 black, 0 0 25px green;
    z-index: 100; }
  .add-shot-modal #add-shot-body .dropdown-menu {
    font-size: 18px;
    max-height: 300px;
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0.7);
    scrollbar-width: thin;
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .add-shot-modal #add-shot-body .dropdown-menu::-webkit-scrollbar {
      width: 2px; }
    .add-shot-modal #add-shot-body .dropdown-menu::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .add-shot-modal #add-shot-body .dropdown-menu::-webkit-scrollbar-thumb {
      background: #888; }
    .add-shot-modal #add-shot-body .dropdown-menu::-webkit-scrollbar-thumb:hover {
      background: #555; }
    .add-shot-modal #add-shot-body .dropdown-menu .dropdown-item {
      color: white;
      word-wrap: none;
      white-space: pre-wrap; }
    .add-shot-modal #add-shot-body .dropdown-menu .dropdown-item:hover {
      background: black;
      font-size: 22px; }
  .add-shot-modal #add-shot-body .choose-round {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 2em; }
    .add-shot-modal #add-shot-body .choose-round .round {
      margin: .2em;
      padding: .6em;
      border-radius: 50%;
      width: 64px;
      height: 64px;
      background-image: url(/static/media/golf-ball3.215b0dd1.png);
      background-repeat: no-repeat;
      background-position: center center;
      border: 1px solid green;
      background-size: cover;
      font-weight: 900;
      color: rgba(0, 128, 0, 0.75);
      -webkit-filter: blur(5);
              filter: blur(5);
      box-shadow: 0 0 0 black, 0 0 8px green;
      font-size: x-large;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
    .add-shot-modal #add-shot-body .choose-round .round.active {
      cursor: pointer;
      background-color: green;
      box-shadow: 0 0 0 black, 0 0 20px green;
      -webkit-transform: scale(1.15);
              transform: scale(1.15);
      z-index: 100; }
    .add-shot-modal #add-shot-body .choose-round .round:hover {
      cursor: pointer;
      box-shadow: 0 0 0 black, 0 0 20px green;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      z-index: 100; }
  .add-shot-modal #add-shot-body input {
    outline: none;
    font-size: large;
    border: 1px solid #16AF78;
    border-radius: 4px;
    border-color: #16AF78;
    padding: 6px;
    margin-bottom: 0;
    box-shadow: 0 0 0 black, 0 0 2px green; }
    .add-shot-modal #add-shot-body input::-webkit-input-placeholder {
      text-transform: capitalize; }
    .add-shot-modal #add-shot-body input:-ms-input-placeholder {
      text-transform: capitalize; }
    .add-shot-modal #add-shot-body input::placeholder {
      text-transform: capitalize; }
  .add-shot-modal #add-shot-body input:hover,
  .add-shot-modal #add-shot-body input:focus {
    box-shadow: 0 0 0 black, 0 0 15px green; }
  .add-shot-modal #add-shot-body span {
    color: #16AF78;
    margin-top: .2em; }
  .add-shot-modal #add-shot-body button {
    font-size: 20px;
    font-weight: bold;
    margin-top: 2em;
    margin-bottom: 2em;
    text-transform: capitalize;
    width: 8em;
    color: white;
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: none;
    background: #16AF78;
    -webkit-filter: blur(5);
            filter: blur(5);
    box-shadow: 0 0 0 black, 0 0 8px green; }
  .add-shot-modal #add-shot-body button:hover {
    cursor: pointer;
    box-shadow: 0 0 0 black, 0 0 18px green; }

.instruction-modal * {
  transition: all .1s ease-in-out;
  background: rgba(0, 0, 0, 0);
  color: white; }

.instruction-modal #instruction-body {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  grid-gap: 2em;
  gap: 2em;
  box-shadow: #1a1a1a 2px 10px 25px 2px; }
  .instruction-modal #instruction-body h4 {
    margin-top: 2em; }
  .instruction-modal #instruction-body .buttons {
    display: -webkit-flex;
    display: flex;
    grid-gap: 2em;
    gap: 2em;
    margin-bottom: 2em; }
    .instruction-modal #instruction-body .buttons button {
      font-size: 20px;
      font-weight: bold;
      text-transform: capitalize;
      width: 8em;
      color: white;
      padding: 10px;
      border-radius: 8px;
      outline: none;
      border: none;
      background: #16AF78;
      -webkit-filter: blur(5);
              filter: blur(5);
      box-shadow: 0 0 0 black, 0 0 8px green; }
    .instruction-modal #instruction-body .buttons button:hover {
      cursor: pointer;
      box-shadow: 0 0 0 black, 0 0 18px green; }

.submit-shot-point-modal * {
  transition: all .1s ease-in-out;
  background: rgba(0, 0, 0, 0);
  color: white; }

.submit-shot-point-modal #submit-shot-point-body {
  display: -webkit-flex;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  box-shadow: #1a1a1a 2px 10px 25px 2px;
  font-size: 1.4em; }
  .submit-shot-point-modal #submit-shot-point-body .box {
    display: grid;
    grid-template-columns: 5em 1fr;
    grid-gap: 4em;
    gap: 4em;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 1em; }
    .submit-shot-point-modal #submit-shot-point-body .box img {
      height: 10em;
      opacity: .9; }
    .submit-shot-point-modal #submit-shot-point-body .box .right {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
  .submit-shot-point-modal #submit-shot-point-body .buttons {
    display: -webkit-flex;
    display: flex;
    grid-gap: 2.5em;
    gap: 2.5em;
    margin-bottom: 1em;
    margin-top: 1em; }
    .submit-shot-point-modal #submit-shot-point-body .buttons button {
      font-size: 20px;
      font-weight: bold;
      text-transform: capitalize;
      width: 8em;
      color: white;
      padding: 10px;
      border-radius: 8px;
      outline: none;
      border: none;
      background: #16AF78;
      -webkit-filter: blur(5);
              filter: blur(5);
      box-shadow: 0 0 0 black, 0 0 8px green; }
    .submit-shot-point-modal #submit-shot-point-body .buttons button:hover {
      cursor: pointer;
      box-shadow: 0 0 0 black, 0 0 18px green; }

