@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;

  font-family: 'Poppins', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  // overflow: hidden;
}

::-webkit-scrollbar {
  width: 0px;
}